// dimension variables can be used anywhere (margin, padding, border size, shadows, etc
// so dont use any prefix that constrains where these might be used
:root {
  --space-xxxs: 1px;
  --space-xxs: 2px;
  --space-xs: 4px;
  --space-s: 8px;
  --space-m: 16px;
  --space-l: 24px;
  --space-xl: 32px;
  --space-xxl: 40px;
  --space-xxxl: 48px;
  --space-4xl: 56px;
  --space-5xl: 64px;
  --space-6xl: 72px;
  --space-7xl: 80px;

  // usage: box-shadows, sticky header, etc
  // elevation documented in storybook already, find under layout / elevation
  --elevation-1: 0px 0.6px 1.8px rgba(0, 0, 0, 0.25);
  --elevation-2: 0px 2px 5px rgba(0, 0, 0, 0.25);
  --elevation-3: 0px 4px 10px rgba(0, 0, 0, 0.25);
  --elevation-4: 0px 11px 15px -7px rgba(0, 0, 0, 0.25), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --elevation-sticky-header: 0 4px 2px -2px rgba(0, 0, 0, 0.25); // legacy, don't use
  --elevation-history-log-side-nav: inset 4px 0px 4px -4px rgba(0, 0, 0, 0.3); // Shadow only on the left side, special case, don't use

  --scroll-bar: 9px;
  --scroll-bar-color: var(--color-blue-light-7);

  --sidebar-min-width: 208px;
  --content-min-width: 864px;
  --right-nav-header: var(--sidebar-header-height);
  --sidebar-item-height: var(--space-xxl);
  --sidebar-item-width: 184px;
  --sidebar-selected-color: var(--color-blue-light-5);
  --responsive-navbar-on-the-side-item-width: 128px;
  --responsive-navbar-on-the-side-item-height: 88px;
  --responsive-navbar-on-the-top-item-height: 64px;
  --responsive-navbar-full-screen-item-height: 76px;

  --z-idx-neg: -1;
  --z-idx-0: 0;
  --z-idx-1: 1;
  --z-idx-standard: 2;
  --z-idx-header: 3;
  --z-idx-shadow: 4;
  --z-idx-elevated: 5;
  --z-idx-tooltip: 6;
  --z-idx-nav: 10;
  --z-idx-env: 100;

  --app-min-width: 1280px;
  --app-max-width: 1600px;

  // variables for border radius and border style e.g. --border-1: 1px solid var(--color-border)
  --modal-border-radius: 12px;
  --button-border-radius: 8px;

  // table variables
  --table-row-border: 1px solid var(--color-grey-light-7);
  --table-total-row-border: 1px solid var(--color-grey-light-9);
}
